<div [class]="controlDivContainerClass">
  <label
    class="clr-control-label"
    *ngIf="showLabelAndDescription"
    [for]="inputTypeHelper.asSelectInput(inputConfig).formControlName"
  >
    {{ inputTypeHelper.asSelectInput(inputConfig).label }}
    <small
      class="required-text"
      *ngIf="inputTypeHelper.asSelectInput(inputConfig).rules?.isRequired"
    >
      (*)
    </small>
  </label>

  <ng-container *ngIf="inputItems$ | async as vm">
    <ng-select
      [class]="inputConfig?.multiple ? '' : 'not-multiple' + (inputConfig?.disabled ? ' disable-arrow disable-dropdown disable-clear-all' : '') "
      [virtualScroll]="true"
      [items]="vm?.state"
      [bindLabel]="'name'"
      [bindValue]="'value'"
      (focus)="onFocus()"
      [loading]="vm?.performingAction"
      [loadingText]="'loadingText' | translate"
      [multiple]="inputConfig.multiple"
      [placeholder]="inputConfig?.placeholder"
      [formControl]="control"
      [groupBy]="'type'"
      [clearable]="true"
      (remove)="multiSelectItemRemove.emit($event)"
      (change)="
        inputSelect.emit({
          formcontrolname: inputConfig?.formControlName,
          value: $event
        })
      "
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-item$="item$"
      >
        <div>
          <label class="form-check-label ml-2">
            {{ item?.name }}
          </label>
        </div>
      </ng-template>
    </ng-select>
  </ng-container>
  <clr-control-error
    class="field-has-error"
    *ngIf="control.hasError('required') && control.touched"
  >
    <span [translate]="'validation.required'"></span>
  </clr-control-error>
  <clr-control-helper
    *ngIf="inputConfig.descriptionText && showLabelAndDescription"
  >
    <span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span>
  </clr-control-helper>
</div>
