
export const appAccessControlList = {
  all: 'all',
  create_departments: 'create-departments',
  create_organisations: 'create-organisations',
  create_authorizations: 'create-authorizations',
  create_roles: 'create-roles',
  delete_departments: 'delete-departments',
  delete_organisations: 'delete-organisations',
  delete_authorizations: 'delete-authorizations',
  delete_roles: 'delete-roles',
  list_departments: 'list-departments',
  list_organisations: 'list-organisations',
  list_authorizations: 'list-authorizations',
  // list_roles: 'list-roles',
  manage_departments: 'manage-departments',
  manage_organisations: 'manage-organisations',
  manage_authorizations: 'manage-authorizations',
  manage_roles: 'manage-roles',
  update_departments: 'update-departments',
  update_organisations: 'update-organisations',
  update_password: 'update-password',
  update_authorizations: 'update-authorizations',
  update_roles: 'update-roles',
  list_modules: 'list-modules',
  create_modules: 'create-modules',
  update_modules: 'update-modules',
  delete_modules: 'delete-modules',



  list_resources:'sygr:resources:list',
  resource_create:'sygr:resources:create',
  resource_update:'sygr:resources:update',
  resource_delete:'sygr:resources:delete',

  list_structures:'sygr:structures:list',
  structure_create:'sygr:structures:create',
  structure_update:'sygr:structures:update',
  structure_delete:'sygr:structures:delete',

  list_users:'sys:users:list',
  user_create:'sys:users:create',
  user_update:'update-users',
  user_delete:'sys:users:delete',

  list_settings:'sygr:settings:list',
  settings_post:'sygr:settings:post',

  list_roles:'sys:authorization-groups:list',
  roles_create:'sys:authorization-groups:create',
  roles_update:'update-roles',
  roles_delete:'sys:authorization-groups:delete',

  list_permissions:'sys:authorizations:list',
  permission_create:'sys:authorizations:create',
  permission_update:'sys:authorizations:update',
  permission_delete:'sys:authorizations:delete',

  list_person:'sygr:persons:list',
  person_create:'sygr:persons:create',
  person_update:'sygr:persons:update',

  list_insurance_companies:'sygr:insurance-companies:list',
  insurance_company_update:'sygr:insurance-companies:update',
  insurance_company_create:'sygr:insurance-companies:create',
  insurance_company_delete:'sygr:insurance-companies:delete',

  list_insurance_types:'sygr:insurance-types:list',
  list_identity_types:'sygr:identity-types:list',
  list_timespans:'sygr:timespans:list',
  list_extern_apps:'sygr:extern-apps:list',

  list_objectives:'sygr:objectives:list',
  objective_create:'sygr:objectives:create',
  objective_update:'sygr:objectives:update',
  objective_delete:'sygr:objectives:delete',

  list_suppliers:'sygr:suppliers:list',
  supplier_create:'sygr:suppliers:create',
  supplier_update:'sygr:suppliers:update',

  list_entries:'sygr:entries:list',
  list_articles:'sygr:articles:list',


  list_points:'sygr:points:list',
  point_update:'sygr:points:update',
  point_create:'sygr:points:create',


  list_article_names:'sygr:article-names:list',

  list_appointments:'sygr:appointment-details:list',
  list_group_appointments:'sygr:group-appointments:list',


  appointment_group_update:'sygr:group-appointments:update',
  appointment_group_create:'sygr:group-appointments:create',


  appointment_update:'sygr:appointment-details:update',
  appointment_create:'sygr:appointment-details:create',
  appointment_update_status:'sygr:appointment-details:update-status',
  appointment_update_report:'sygr:appointment-details:update-report',


};


export const authorizations = [
  appAccessControlList.create_departments,
  appAccessControlList.update_departments,
  appAccessControlList.delete_departments,
  appAccessControlList.list_departments,
  appAccessControlList.manage_departments,
  appAccessControlList.create_modules,
  appAccessControlList.update_modules,
  appAccessControlList.delete_modules,
  appAccessControlList.list_modules,
  appAccessControlList.create_roles,
  appAccessControlList.update_roles,
  appAccessControlList.delete_roles,
  appAccessControlList.list_roles,
  appAccessControlList.create_organisations,
  appAccessControlList.update_organisations,
  appAccessControlList.delete_organisations,
  appAccessControlList.list_organisations,
];
