export const environment = {
  production: true,
  testUser: '',
  testUserPassword: '',
  applicationUniqueID: 'ELEWOU_ADMIN_',
  userInfoStorageKey: 'ELEWOU_ADMIN_USER_INFO',
  authTokenStorageKey: 'ELEWOU_ADMIN_X_AUTH_TOKEN',
  authRememberTokenStorageKey: 'ELEWOU_ADMIN_AUTH_REMEMBER_TOKEN',
  forms: {
    roles: null,
    authorizations: null,
    users: null,
    departments: null,
    modules: null,
    globalConfigurationForm: null
  },
  APP_SERVER_URL: 'https://appointments-api.hdl.tg/api/',
  APP_FILE_SERVER_URL: 'https://appointments-api.hdl.tg/api/',
  AUTH_SERVER_URL: 'https://appointments-dev.hdl.tg',
  FORM_SERVER_URL: '',
  APP_SECRET: 'V1HQkt03PoGdlxN',
  API_APP_KEY:'XGDgn0wv7OFbmUOUzebGSDOzbPRrLNZKjL/EE3v6XjA=',
  isDepartmentWorkspaceEnabled: false,
  isModulesWorkspaceEnabled: false,
  isFormsWorkspaceEnabled: true,
  isConfigurationWorkspaceEnabled: false,
  toApi: {
    user: 'users',
    permission: 'permissions',
    profil: 'profils',
    point: 'points',
    client: 'persons',
    supplier: 'suppliers',
    resource: 'resources',
    timespan: 'timespans',
    entry: 'entries',
    article: 'articles',
    tree: 'trees',
    groupedappointment:'group-appointments',
    article_name:'article-names',
    entrydetail :'entry-details' ,
    identitytype:'identity-types',
    objective:'objectives',
    structure:'structures',
    schedule: 'appointments',
    insurancetype : 'insurance-types',
    insurancecompany : 'insurance-companies' ,
    objectivestrtuctures : 'structure-objectives',
    config : 'settings' ,
  },
};
