<clr-datagrid #clrDataGrid *ngIf="showGrid" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <ng-container *ngFor="let item of dataFields">
        <clr-dg-column [clrDgField]="item.field">{{ item.name }}</clr-dg-column>
    </ng-container>

    <clr-dg-row *ngFor="let item of dataSource">
        <clr-dg-action-overflow *ngIf="canEditItem || canDeleteItem || canViewItem">
            <button class="action-item" *ngIf="canEditItem" (click)="editItemEvent.emit(item)">{{ 'edit' | translate }}</button>
            <button class="action-item" *ngIf="canDeleteItem" (click)="deleteItemEvent.emit(item)">
        {{ 'delete' | translate }}</button>
            <button class="action-item" *ngIf="canViewItem" (click)="viewItemEvent.emit(item)">
        {{ 'view' | translate }}</button>
        </clr-dg-action-overflow>
        <ng-container *ngFor="let i of dataFields">
            <clr-dg-cell>
                <div [class]="getContentStyleClass(item, i)">
                    {{ getItemValue(item, i.field) ? getItemValue(item, i.field) : i.defaultValue }} {{ i.suffix ? i.suffix : ''}}
                </div>
            </clr-dg-cell>
        </ng-container>
    </clr-dg-row>

    <clr-dg-footer>
        <!-- Always remember to put this before the pagination component -->
        {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }}
        <clr-dg-pagination [clrDgPageSize]="pageSize" #pagination [clrDgTotalItems]="totalCount">
            <clr-dg-page-size [clrPageSizeOptions]="allowedPageSizes"></clr-dg-page-size>
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>