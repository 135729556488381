import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { partialConfigs, authorizations } from './config/partials-configs';
import { AuthGuardService ,AuthorizationsGuard } from './lib/core/auth/core';
import { NotfoundComponent } from './views/pages/notfound/notfound.component';




const routes: Routes = [
  // {
  //   path: 'admin',
  //   redirectTo: '/dashboard',
  //   pathMatch: 'full'
  // },
  {
    path: 'login',
    loadChildren: () => import('./lib/views/login/login.module').then(m => m.LoginModule),
    data: {
      dashboardPath: partialConfigs.routes.commonRoutes.dashboardHomeRoute,
      modulePermissions: authorizations,
      moduleName: 'Administration'
    }
  },
  {
    path: 'dashboard',
    redirectTo: '',
    pathMatch: 'full'
  },

  { path: '',
   loadChildren: () => import('./modules/points/points.module').then(m => m.PointsModule),
   canLoad: [AuthGuardService],
  },

  { path: 'appointments',
   loadChildren: () => import('./modules/appointments/appointments.module').then(m => m.AppointmentsModule) ,
    canLoad: [AuthGuardService],
  },

  { path: 'r-appointments',
   loadChildren: () => import('./modules/resource-appointments/appointments.module').then(m => m.ResourceAppointmentsModule) ,
    canLoad: [AuthGuardService],
  },



  { path: 'administration',
  loadChildren: () => import('./modules/administration/administration.module').then(m => m.AdministrationModule) ,
    canLoad: [AuthGuardService],
  },


  {
    path: '**',
    component: NotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
