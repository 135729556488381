
export const appAccessControlList = {
  all: 'all',
  create_departments: 'create-departments',
  create_organisations: 'create-organisations',
  create_authorizations: 'create-authorizations',
  create_roles: 'create-roles',
  delete_departments: 'delete-departments',
  delete_organisations: 'delete-organisations',
  delete_authorizations: 'delete-authorizations',
  delete_roles: 'delete-roles',
  list_departments: 'list-departments',
  list_organisations: 'list-organisations',
  list_authorizations: 'list-authorizations',
  list_roles: 'list-roles',
  manage_departments: 'manage-departments',
  manage_organisations: 'manage-organisations',
  manage_authorizations: 'manage-authorizations',
  manage_roles: 'manage-roles',
  update_departments: 'update-departments',
  update_organisations: 'update-organisations',
  update_password: 'update-password',
  update_authorizations: 'update-authorizations',
  update_roles: 'update-roles',
  list_modules: 'list-modules',
  create_modules: 'create-modules',
  update_modules: 'update-modules',
  delete_modules: 'delete-modules'
};
