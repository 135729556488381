<div [class]="controlDivContainerClass">
  <label class="clr-control-label" *ngIf="showLabelAndDescription"
    [for]="inputTypeHelper.asNumberInput(inputConfig).formControlName">{{ inputTypeHelper.asNumberInput(inputConfig).label }}
    <span class="required-text" *ngIf="inputTypeHelper.asNumberInput(inputConfig).rules?.isRequired">(*)</span></label>
  <div class="clr-control-container">
    <div class="clr-input-wrapper">
      <input [tabIndex]="inputConfig?.formControlIndex" (keyup)="inputKeyUp.emit({formcontrolname: inputConfig?.formControlName, value: $event})"
        (keydown)="inputKeyDown.emit({formcontrolname: inputConfig?.formControlName, value: $event})"
        (keypress)="inputKeypress.emit({formcontrolname: inputConfig?.formControlName, value: $event})"
        (blur)="inputBlur.emit({formcontrolname: inputConfig?.formControlName, value: $event})" type="number" [max]="
              inputTypeHelper.asNumberInput(inputConfig).max
                  ? inputTypeHelper.asNumberInput(inputConfig).max
                  : maxNumberSize()
              " [min]="
              inputTypeHelper.asNumberInput(inputConfig).min
                  ? inputTypeHelper.asNumberInput(inputConfig).min
                  : 0
              " [class]="inputTypeHelper.asNumberInput(inputConfig).classes" [formControl]="control"
        [placeholder]="inputTypeHelper.asEmailInput(inputConfig).placeholder ? inputTypeHelper.asEmailInput(inputConfig).placeholder : ''" />
    </div>
    <clr-control-error class="field-has-error" *ngIf="control.hasError('required') && control.touched"><span [translate]="'validation.required'"></span></clr-control-error>
    <clr-control-error class="field-has-error" *ngIf="control.hasError('min') && control.touched"><span [translate]="'validation.min'" [translateParams]="{ value: getErrorAsNumber(control.errors['min'], 'min') ?  getErrorAsNumber(control.errors['min'], 'min') : inputTypeHelper.asNumberInput(inputConfig).min }"></span>
    </clr-control-error>
    <clr-control-error class="field-has-error" *ngIf="control.hasError('max') && control.touched"><span [translate]="'validation.max'" [translateParams]="{ value: getErrorAsNumber(control.errors['max'], 'max') ?  getErrorAsNumber(control.errors['max'], 'max') : inputTypeHelper.asNumberInput(inputConfig).max  }"></span>
    </clr-control-error>
    <clr-control-helper *ngIf="inputConfig.descriptionText && showLabelAndDescription"><span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span></clr-control-helper>
  </div>
</div>
