<clr-radio-container clrInline="inline">
  <label>
    {{ inputTypeHelper.asRadioInput(inputConfig).label }}
    <span
      class="required-text"
      *ngIf="inputTypeHelper.asRadioInput(inputConfig).rules?.isRequired"
    >
      (*)
    </span>
  </label>
  <clr-radio-wrapper
    *ngFor="
      let item of inputTypeHelper.asRadioInput(inputConfig).items;
      let i = index
    "
  >
    <input
      [tabIndex]="inputConfig?.formControlIndex"
      type="radio"
      clrRadio
      required
      [disabled]="control?.status == 'DISABLED'"
      [checked]="control.value == item.value"
      [value]="inputValue(inputConfig?.formControlName, item?.value)"
      (change)="onValueChanges(item.value)"
    />
    <label>{{ item.description }}</label>
  </clr-radio-wrapper>
</clr-radio-container>
<clr-control-helper
  *ngIf="inputConfig.descriptionText && showLabelAndDescription"
>
  <span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span>
</clr-control-helper>
<clr-control-error
  class="field-has-error"
  *ngIf="control.hasError('required') && control.touched"
>
  <span [translate]="'validation.required'"></span>
</clr-control-error>
