<clr-alert [clrAlertType]="'warning'" [clrAlertAppLevel]="true" *ngIf="!online" [clrAlertClosable]="">
    <clr-alert-item>
        <span class="alert-text">
      <span>
        Vous semblez être déconnecté. Tentative de reconnection en
        cours...</span
      >
    </span>
    </clr-alert-item>
</clr-alert>
<clr-alert [clrAlertType]="'success'" [clrAlertAppLevel]="true" *ngIf="online && wasOffline && !hideSuccessAfterTimeout">
    <clr-alert-item>
        <span class="alert-text">Votre connection est rétablie...</span>
    </clr-alert-item>
</clr-alert>
