import { backendRoutes } from '../lib/views/partials/backend-route-paths';
import { appAccessControlList } from '../lib/views/partials/access-control-list';
import { routeDefinitions as adminModuleRoutes } from '../lib/views/partials/admin-route-definitions';

/**
 * This file defines configuration values that are shared accross modules
 */
export const partialConfigs = {
  routes: {
    commonRoutes: {
      dashboardRoute: 'dashboard',
      dashboardHomeRoute: 'dashboard/home',
      careerRoute: 'career',
      careerHomeRoute: 'career/home',
      adminRoute: 'admin',
      adminHomeRoute: 'admin/home',
      homeRoute: 'home',
    },
    adminModuleRoutes
  },
  acl: appAccessControlList,
  backendRoutesPaths: backendRoutes
};

export const routeDefinitions = {

  //mine


  dashboardRoute: 'dashbord',
  searchRoute: 'search',

  centersRoute: 'centers',
  appointmentsRoute : 'appointments',
  resourceappointmentsRoute: 'r-appointments',
  administrationRoute: 'administration',
  searchsRoute : 'search',
  suppliersRoute : 'suppliers',
  identityTypesRoute : 'identity-types',
  insuranceTypesRoute : 'insurance-types',
  insuranceCompaniesRoute : 'insurance-companies',
  extern_appsRoute:'extern-apps',
  gridsRoute:'grids',
  timespanRoute:'timespans',
  userRoute:'users',
  treeRoute:'trees',
  permissionRoute:'permissions',
  pointsRoute:'points',
  userprofilRoute:'profils',
  roleRoute:'roles',
  article_nameRoute: 'artile-names' ,
  entriesRoute: 'entries' ,
  articlesRoute: 'articles',
  clientsRoute:'persons',
  resourcesRoute:'resources',
  generalRoute:'general',
  testRoute:'test',
  objectivesRoute:'services',
  // list routes
  listRoute: 'lists',
  // creation routes
  createRoute: 'create',
  // update routes
  updateRoute: 'update',
  // Entrie routes
  listEntriesRoute: 'Entries/list',
  configurationsRoute: 'configurations',


  //not mine

  // Account routes
  accountRoute: 'account',
  // Roles management routes
  rolesManagementRoute: 'roles',
  // Role creation routes
  createRole: 'roles/create',
  // Role update route
  updatedRoleRoute: 'roles/update',
  // User creation routes
  createUsersRoute: 'users/create',
  // Users update route
  updatedUserRoute: 'users/update',

  // Forms management routes
  formsManagementRoute: 'forms',
  // Forms creation routes
  createFormsRoute: 'forms/create',
  // Module management routes
  modulesManagementRoute: 'modules',
  // Module management routes
  createModulesRoute: 'modules/create',
  // Module management routes
  updateModulesRoute: 'modules/update',
  // Department management route
  departmentManagementRoute: 'departments',
  // Module management routes
  createDepartmentRoute: 'department',
  // Configurations
  globalConfigurationsRoute: 'configurations-globales',

  // Form Control options route
  controlOptionsRoute: 'form-control-options',
};

// Add new constants here for module path
// Default Dashboard path
export const defaultPath = `/${partialConfigs.routes.commonRoutes.dashboardRoute}`;
// Admin module path
export const adminPath = adminModuleRoutes;
// Route definitions for backend ressources
export const backendRoutePaths = backendRoutes;


// Fixed value of the currency used in the application
export const APPLICATION_CURRENCY = 'XOF';
// File types definitions
export const imagesMimeExtensions = ['bmp', 'gif', 'ico', 'jpg', 'jpeg', 'png', 'svg', 'tiff', 'tif', 'webp'];


// Export authorizations
// export { adminAuthorizations } from '../lib/views/partials/modules-authorizations';
export { authorizations ,appAccessControlList } from './access-control-list';

export type MultidimentionalArray = Array<String>| Array<any>|ReadonlyArray <MultidimentionalArray>;
