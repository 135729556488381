<div [class]="controlDivContainerClass">
  <label
    [for]="inputConfig.formControlName"
    class="clr-control-label"
    *ngIf="showLabelAndDescription"
  >
    {{ inputTypeHelper.asPasswordInput(inputConfig).label }}
    <small
      class="required-text"
      *ngIf="inputTypeHelper.asPasswordInput(inputConfig).rules?.isRequired"
    >
      (*)
    </small>
  </label>
  <div class="clr-control-container">
    <div
      class="clr-input-wrapper password-clr-input-wrapper"
      *ngIf="state$ | async as state"
    >
      <input
        [tabIndex]="inputConfig?.formControlIndex"
        (keyup)="
          inputKeyUp.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (keydown)="
          inputKeyDown.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (keypress)="
          inputKeypress.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (blur)="
          inputBlur.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        [autocomplete]="'off'"
        [type]="!state.showPassword ? 'password' : 'text'"
        autocomplete="off"
        [maxLength]="
          inputTypeHelper.asPasswordInput(inputConfig).maxLength
            ? inputTypeHelper.asPasswordInput(inputConfig).maxLength
            : maxNumberSize()
        "
        [minLength]="
          inputTypeHelper.asPasswordInput(inputConfig).minLength
            ? inputTypeHelper.asPasswordInput(inputConfig).minLength
            : 0
        "
        [class]="
          inputTypeHelper.asPasswordInput(inputConfig).classes +
          ' password-input'
        "
        [formControl]="control"
        [placeholder]="
          inputTypeHelper.asPasswordInput(inputConfig).placeholder
            ? inputTypeHelper.asPasswordInput(inputConfig).placeholder
            : ''
        "
      />
      <clr-icon
        shape="eye"
        (click)="togglePassWordInput()"
        *ngIf="state.showPassword"
        class="password-trigger"
      ></clr-icon>
      <clr-icon
        shape="eye-hide"
        (click)="togglePassWordInput()"
        *ngIf="!state.showPassword"
        class="password-trigger"
      ></clr-icon>
    </div>
    <clr-control-error
      class="field-has-error"
      *ngIf="control.hasError('required') && control.touched"
    >
      <span [translate]="'validation.required'"></span>
    </clr-control-error>
    <clr-control-error
      class="field-has-error"
      *ngIf="control.hasError('maxLength') && control.touched"
    >
      <span
        [translate]="'validation.maxValue'"
        [translateParams]="{
          value: inputTypeHelper.asPasswordInput(inputConfig).maxLength
        }"
      ></span>
    </clr-control-error>
    <clr-control-error
      class="field-has-error"
      *ngIf="control.hasError('minLength') && control.touched"
    >
      <span
        [translate]="'validation.minValue'"
        [translateParams]="{
          value: inputTypeHelper.asPasswordInput(inputConfig).minLength
        }"
      ></span>
    </clr-control-error>
    <clr-control-error
      class="field-has-error"
      *ngIf="control.hasError('pattern') && control.touched"
    >
      <span [translate]="'validation.passwordPattern'"></span>
    </clr-control-error>
    <clr-control-error
      class="field-has-error"
      *ngIf="control.hasError('Match') && control.touched"
    >
      <span [translate]="'validation.passwordMatch'"></span>
    </clr-control-error>
    <clr-control-helper
      *ngIf="inputConfig.descriptionText && showLabelAndDescription"
    >
      <span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span>
    </clr-control-helper>
  </div>
</div>
