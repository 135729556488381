<div [class]="controlDivContainerClass">
  <label
    class="clr-control-label"
    *ngIf="showLabelAndDescription"
    [for]="inputTypeHelper.asTextAreaInput(inputConfig).formControlName"
  >
    {{ inputTypeHelper.asTextAreaInput(inputConfig).label }}
    <span
      class="required-text"
      *ngIf="inputTypeHelper.asTextAreaInput(inputConfig).rules?.isRequired"
    >
      (*)
    </span>
  </label>
  <div class="clr-control-container">
    <div class="clr-textarea-wrapper">
      <textarea
        (keyup)="
          inputKeyUp.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (keydown)="
          inputKeyDown.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (keypress)="
          inputBlur.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        (blur)="
          inputKeypress.emit({
            formcontrolname: inputConfig?.formControlName,
            value: $event
          })
        "
        [class]="inputTypeHelper.asTextAreaInput(inputConfig).classes"
        [formControl]="control"
        [placeholder]="
          inputTypeHelper.asEmailInput(inputConfig).placeholder
            ? inputTypeHelper.asEmailInput(inputConfig).placeholder
            : ''
        "
        [rows]="inputTypeHelper.asTextAreaInput(inputConfig).rows"
      ></textarea>
    </div>
  </div>
  <clr-control-error
    class="field-has-error"
    *ngIf="control.hasError('required') && control.touched"
  >
    <span [translate]="'validation.required'"></span>
  </clr-control-error>
  <clr-control-error
    class="field-has-error"
    *ngIf="control.hasError('maxLength') && control.touched"
  >
    <span
      [translate]="'validation.maxValue'"
      [translateParams]="{
        value: inputTypeHelper.asTextAreaInput(inputConfig).maxLength
      }"
    ></span>
  </clr-control-error>
  <clr-control-helper
    *ngIf="inputConfig.descriptionText && showLabelAndDescription"
  >
    <span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span>
  </clr-control-helper>
</div>
