<div *ngIf=" inputConfig && inputTypeHelper.asCheckBoxInput(inputConfig).items.length > 0 && control">
  <div [class]="controlDivContainerClass">
    <label class="clr-control-label" *ngIf="showLabelAndDescription"
      [for]="inputTypeHelper.asCheckBoxInput(inputConfig).formControlName">
      {{ inputTypeHelper.asCheckBoxInput(inputConfig).label }}<span class="required-text"
        *ngIf="inputTypeHelper.asCheckBoxInput(inputConfig).rules?.isRequired">(*)</span>
    </label>
    <div class="clr-control-container clr-control-inline" >
      <div class="clr-checkbox-wrapper"
        *ngFor="let order of inputTypeHelper.asCheckBoxInput(inputConfig)?.items;let i = index">
        <input [tabIndex]="inputConfig?.formControlIndex+i" [id]="inputTypeHelper.asCheckBoxInput(inputConfig).formControlName + '_' + i" type="checkbox"
          [formControlName]="i" [value]="inputTypeHelper.asCheckBoxInput(inputConfig)?.items[i]?.value"
          class="clr-checkbox" [name]="'options'+i" />
        <label [for]="inputTypeHelper.asCheckBoxInput(inputConfig).formControlName + '_' + i" class="clr-control-label"
          *ngIf="showLabelAndDescription"
         >{{ inputTypeHelper.asCheckBoxInput(inputConfig).items[i].description }}</label>
      </div>
    </div>
    <clr-control-error class="field-has-error" *ngIf="control.hasError('required') && control.touched"><span
        [translate]="'validation.required'"></span></clr-control-error>
    <clr-control-helper *ngIf="inputConfig.descriptionText && showLabelAndDescription">
      <span [innerHTML]="inputConfig.descriptionText | safeWebContent"></span></clr-control-helper>
  </div>
</div>
